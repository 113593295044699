const lang_zh = {
    "node":'节点',
    "nftMining":'NFT 挖矿',
    "mine":'我的',
    "mynode":'我的节点',
    "myvote":'我的投票',
    "mynft":'我的NFT',
    "nodeelection":'节点竞选',
    "wantrun":'我要参选',
    "fuhecanxuanzigejiedianshu":'符合资格节点',
    "bufuhecanxuanzigejiedianshu":'不符合资格节点',
    "jiedianzongdepiaoshu":'节点总得票数',
    "canxuanjeidianliebiao":'参选节点列表',
    "fenpeibili":'分配比例',
    "toupiaorenshu":'投票人数',
    "dangqiandepiao":'当前得票',
    "canxuanzige":'参选资格',
    "wotoupiaode":'我投票的',
    "fuhe":'符合',
    "bufuhe":'不符合',
    "toupiao":'投票',
    "fanhui":'返回',
    "canxuanjiedian":'参选节点',
    "julicanxuanjeishuhaiyou":'距离参选结束还有',
    "jiedianmingcheng":'节点名称',
    "jiediankouhao":'节点口号',
    // "fenpeibili":'分配比例',
    "diyadaibishu":'抵押代币数',
    "shurujeidianmc":'请输入节点名称，限30个字',
    "shurujiediankuhao":'请输入节点口号，限50个字',
    "shurubili":'请输入节点收入分配给投票用户的比例，50~100之间整数位',
    "shurudaibi":'请输入参选节点抵押TFI代币数。最小2100 TFI',
    "keyongyue":'可用余额',
    "querencanxuan":'确认参选',
    "canxuanjiedianguize":'参选节点规则',
    "tishixinxi":'请您按照提示信息填写信息',
    "canxuanchengong":'参选成功',
    "querenzhong":'确认中',
    "guangbojiaoyizhong":'广播交易中',
    "toupiaojiedianshu":'投票节点数',
    "toupiaozongshu":"投票总数",
    "toupiaoliebiao":'投票节点列表',
    "toupiaoshu":'投票数',
    "zhuijiatoupiao":'追加投票',
    "shouyifenpeibili":'收益分配比例',
    "jiediandepiaozpongshu":'节点得票总数',
    "yonghutoupiaoshu":'用户投票数',
    "jiediantoupiaoshu":'节点投票数',
    "jiediancanxuanzhiyashu":'节点参选质押数(TFI)',
    "jiedianzhuanshulianjie":'节点专属链接',
    "toupiaoyonghuliebiao":'投票用户列表',
    "piao":'票',
    "fuzhishibai":'复制失败',
    "toupiaogei":'投票给',
    "toupiaoshuliang":'投票数量',
    "queding":'确定',
    "toupiaochenggong":'投票成功',
    "shuhuishuliang":'赎回数量',
    "shuhuichenggong":'赎回成功',
    "zhanghuyuebuzu":'账户余额不足',
    "tian":'天',
    "shi":'时',
    "fen":'分',
    "miao":'秒',
    "shuhuishulaingbuke":'赎回数量不可超过STFI数量',
    "shuhuishulaingbuzu":'可赎回数量不足',
    "chuangjianjiedian":'您已创建节点',
    "meiyouchuangjianjiedian":'您还没有创建节点',
    'qingshuruzhengqueshuzhi':'请输入正确数值',
    "bukechongfu":'节点已参选，不可重复参选',
    "yijieshu":'已结束',
    "jingxuanjieshu":'竞选已结束',
    "NFTwakaung":'NFT挖矿',
    "wakuangshuoming":'抵押 sTFI 到 NFT 挖矿池，通过挖到的 xTFI兑换 Honor、Benefit 两个等级 NFT',
    "wakuangshuomingqian":'抵押 sTFI 到 NFT 挖矿池，通过挖到的 xTFI兑换 Honor、',
    "wakuangshuominghou":'Benefit 两个等级 NFT',
    "STFIdiyazonglaing":"sTFI抵押总量",
    "yichanchujifen":'每日产出 xTFI',
    "nftduihuanshu":"NFT兑换数",
    "jifen":'xTFI',
    "daiduihuajifen":'待兑换 xTFI',
    "duihuanNFT":"兑换NFT",
    "yizhiyaSTFI":"已质押 sTFI",
    "zhiya":"质押",
    "NFTwakaungguize":'NFT挖矿规则 :',
    "a_shouquan": '授权',   //
    "a_shouquanchenggong": '授权成功', //
    "a_shuhui": '赎回',  //    
    "a_lianjieqianbao": '连接钱包',   ///
    "chiyouNFTzongshu":"持有总数",
    "honorNFTshu":"Honor",
    "BenefitNFTshu":'Benefit',
    "wodenftliebiao":"我的NFT列表",
    "quanbu":'全部',
    'haimeiyoutupian':"还没有NFT图片哦~",
    "a_zuida":'最大',
    "a_keyong":'可用',
    "a_haode": '好的',  //
    "a_chakanjiaoyi": '查看交易', //
    "a_fuzhichneggon": '复制成功', //
    "a_querenzhong": '确认中',
    "bukeshuhui":'不可赎回',
    "jiaoyichenggong":'交易成功',
    "zanwuoupiao":'暂无投票',
    "zanwujiedian":'暂无节点',
    // "zhiya":'质押',
    "a_queren":'确认',
    'xnftduihuannft':"xTFI兑换NFT",
    'nftleixing':"NFT类型",
    'duihuannftshulaing':'兑换NFT数量',
    'duihuanbili':'兑换比例:',
    'dangqiankelignqu':"当前可领取",
    'yilingqu':"已领取",
    "NFTwakaungguizeone":'投票可获得sTFI，抵押sTFI可进行挖矿',
    "NFTwakaungguizetwo":'挖出的xTFI不可直接体现，只能兑换成NFT',
    "NFTwakaungguizethree":'持有NFT可享有各种平台权益',
    "shuruzishuchaochuxianzhi":'输入字数超出限制',
    "xinxitianxiebueanzheng":'信息填写不完整',
    "bukeshuruxiaoshu":"不可输入小数",
    "qingshuruguidingfanweineibili":'请输入规定范围内的比例',
    "shuridaibixiaoyuzuixiao":'输入代币数小于最小规范',
    'zhongzilun':'种子轮剩余锁仓量',
    "simulun":"私募轮剩余锁仓量",
    'lingquchenggong':'领取成功',
    'jiediancanxuanguizeone':'填写信息并提交，便可成为候选节点',
    'jiediancanxuanguizetwo':'报名时质押的TFI需要进行锁仓，至竞选期结束后，失败的节点可直接取回。竞选成功的节点需等整个STAKING周期结束后才可取回',
    'jiediancanxuanguizethree':'投票人数达到5人，票数达到5000票，有机会成为正式节点',
    'jiediancanxuanguizefour':'竞选期结束后，所有达到条件的节点的前2/3成为正式节点',
    "popo":'成为候选节点条件：投票人数达到5人，票数达到5000票。竞选期结束后，所有达到条件的节点的前2/3成为正式节点。',
    "zuiduokeduihuan":'每次最多可兑换10个NFT',
    "duihuanchenggong":"兑换成功",
    "zhiyachenggong":'质押成功',
    "bukegeigaijeidiantoupiao":"不可给该节点投票",
    "xiugaixinxi":'修改信息',
    "quxiao":'取消',
    "baocunxinxi":'保存信息',
    "xiugaichenggong":"修改成功",
    //二期
    "wodezhiya":'我的质押',
    "zhengshijiedian":'正式节点',
    "zhengxiang":'正式节点享受每日质押收益',
    "officialNum":'正式节点数',
    "nodetotalpl":'节点总质押(TFI)',
    "noderevenue":'节点总收益(TFI)',
    "pledgepeople":'质押人数',
    "pledgetotal":"质押总数(TFI)",
    "totalrevenue":"累计收益(TFI)",
    "pledged":'我质押的',  
    "pledgeUserList":'质押用户列表',
    "pledgeNums":'质押总量(TFI)' ,
    "onedayrevenue":'每日收益/节点(TFI)',
    "RedeemedNFT":'已兑换NFT数',
    "leijifafangshouyi":'累计发放收益(TFI)',
    "estimated":'今日预估发放收益(TFI)',
    "pendingrevenue":'待领取节点收益',
    "pendingrevenue1":'待领取质押收益',
    "receive":'领取',
    "percentage":'占比',
    "pledgenodenum":'质押节点数',
    "leijizhiyahsouyi":'累计领取收益(TFI)',
    "dailingquzhiyashouyi":'待领取质押收益(TFI)',
    "clickreceive":'一键领取',
    "totalple":"总质押数(TFI) : ",
    "myple":"我的质押(TFI) ：",
    "estimatedday":'预估今日收益(TFI) : ',
    "jixuzhiya":"继续质押",
    "pleFor":'质押给',
    "pleSucc":'质押成功',
    "unPle":'暂无质押',
    "nono":'当前可领取余额为零',
    "pr":'该节点竞选失败，请尽快取回质押代币',




    


    //AccountInfo
    "a_liulanqichakan": '浏览器查看',
    "a_genghuanqianbao": '更换钱包',
    "a_duankailianjie": '断开连接',
   
    
    
   
   
}
export default lang_zh
