import Vue from 'vue'
import App from './App.vue'
import './assets/css/global.css';
import './registerServiceWorker'
import router from './router'
import store from './store'
import './setRem'
import 'jquery'
import { Tabbar, TabbarItem, Button, Toast, Swipe, SwipeItem, Lazyload, Dialog,Tab, Tabs,List } from 'vant';
import { DropdownMenu, DropdownItem } from 'vant';
import { Field } from 'vant';
import { Icon } from 'vant';
import { Slider } from 'vant';
import { Popover } from 'vant';
import { Collapse, CollapseItem } from 'vant';
import VueClipboard from 'vue-clipboard2'
import {setUnit,BigNumber,
  BigNumberStr,
  BigNumberMul,
  BigNumberDiv,
  BigNumberAdd,
  BigNumberSub,} from "./tools";
// import { BigNumberStr,BigNumberdiv,BigNumberMul } from './tools/tools'
// import { bignumber } from './bignumber'
import '@vant/touch-emulator';

Vue.use(Popover);
Vue.use(Slider);
Vue.use(Icon);
Vue.use(Field);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(VueClipboard);

Vue.config.productionTip = false
Vue.use(Button).use(Toast).use(Swipe).use(SwipeItem).use(Tab).use(List).use(Tabs).use(Lazyload).use(Tabbar).use(TabbarItem).use(Dialog);
Vue.prototype.setUnit =  setUnit;
Vue.prototype.$getFullDisplayBalance = (balance, decimals = 18) => {
  balance = new BigNumber(balance)
  return balance.dividedBy(new BigNumber(10).pow(decimals)).toFixed()
}
Vue.prototype.BigNumberMul=BigNumberMul
Vue.prototype.BigNumberDiv=BigNumberDiv
Vue.prototype.BigNumberStr=BigNumberStr

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

