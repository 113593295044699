// debug true:测试  false:正式
const devUrl = ["http://3.0.61.146:7000/#/", "localhost", "192.168"];

const getEnv = () => {
  const link = window.location.href;
  let isDebug = false;
  for (let k in devUrl) {
    if (link.indexOf(devUrl[k]) !== -1){
      isDebug = true;
    }
  }
  return isDebug;
}
let debug = getEnv();

/* 正式环境 */
//合约地址
let voteAddress = '0x34B0168686BD8E8FC0E723642170d9aAe2CceD5B'; // 投票 -- 0x68b139d6c6401EFb0257Eb5Ad4E5E1a448044be5
let nft_tokenAddress='0x7565ab68D3F9DaDff127F864103C8c706Cf28235'// NFT代币地址
let r_TokenAddress='0xdd5247926bbBbE4e35537287eD690b74C4A41A1f' // RTFI -- 0x0AEe71E4886B7765B567662F1EcEB324492a3290
let miningAddress='0xd028AD160BF28192C4877b53032F92dd5231258e' // 挖矿 -- 0x2E4Aa0b485b71C34Ef42F2D11c8D874c60C9DfFc
let nftInfoAddress='0xe86E426BC71a9aB7a2056432491Ca8e82B20CaE7'
let lockUpAddress='0x113C5aA47fbC746Ae65A548B5b9792B867eD88C0'

if (debug) {
  /* 测试环境 */
  //合约地址
  voteAddress = '0xAa75bBD323337BA52F910FF6EabBeD724200B135'; // 投票 -- 0x76344008464eedE730f800c56AD98dbCD69338ba
  nft_tokenAddress='0x8706FEb7F9252DC97f21A612e3298B29c74DE914' //nft
  r_TokenAddress='0xF287928f4B092D0861F55D1DBd750239f3FD566E' //RFTI新代币授权
  miningAddress='0xaD9d62aB2c8d80Aaf03a438B219b06cB83840364' //挖矿
  nftInfoAddress='0x816e9cd15b0eAa99a7DB139bd8a9eA7C370eEF3A' //nft查询
  lockUpAddress='0x0b3E9Fa6AedcC7B8fDc1A11Ea4Dee6B316983D5A' //锁仓
}


export {
  voteAddress,
  nft_tokenAddress,
  r_TokenAddress,
  miningAddress,
  nftInfoAddress,
  lockUpAddress
}
