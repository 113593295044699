import Web3 from 'web3';
import 'vant/lib/toast/style';
import Toast from "vant/lib/toast";
import store from "../store";

import abiERC20 from './abiERC20';
import abiERC721 from './abiERC721';
import abiVote from './abiVote';
import abimining from './abimining';
import abiNFTList from './abiNFTList';
import abiLockUp from './abiLockUp';
import {connectWallet, connectMetaMask} from './walletConnectTools'
import {
  BigNumber,
  BigNumberStr,
  BigNumberMul,
  BigNumberDiv,
  BigNumberAdd,
  BigNumberSub,
  setUnit
} from './index.js'
import {
  voteAddress,
  nft_tokenAddress,
  r_TokenAddress,
  miningAddress,
  nftInfoAddress,
  lockUpAddress
} from "../config"

const initWeb3hooks = async (type) => {
  if (type === 0) {
    await connectMetaMask();
    await initParams();
    store.dispatch("initData");
  } else {
    await connectWallet();
  }
};

const initWeb3 = async (type) => {
  try {
    await initWeb3hooks(type);
    // localStorage.setItem('accountsType',type);
  } catch (e) {
    Toast.fail(e)
  }
}
let account = localStorage.getItem('accounts');
if (account) {
  initWeb3(0)
}
const initParams = async () => {
  let ethereum = window.ethereum;
  if (!ethereum){
    window.globalWeb3 = new Web3(Web3.givenProvider);
  } else {
    window.globalWeb3 = new Web3(ethereum);
  }
 window.voteContract = await new window.globalWeb3.eth.Contract(abiVote, voteAddress);//投票合约
 window.voteNumContract = await new window.globalWeb3.eth.Contract(abiERC20, nft_tokenAddress);//投票合约
 window.rTokenContract = await new window.globalWeb3.eth.Contract(abiERC20, r_TokenAddress);//投票合约
 window.miningContract = await new window.globalWeb3.eth.Contract(abimining, miningAddress);//投票合约
 window.nftInfoContract = await new window.globalWeb3.eth.Contract(abiNFTList, nftInfoAddress);//nft查询
 window.lockUpContract = await new window.globalWeb3.eth.Contract(abiLockUp, lockUpAddress);//锁仓

};
//领取质押收益
const getPleReceive = (account) => {
  return new Promise(async (resolve, reject) => {
    try {
      Toast.loading({
        duration: 0,
      });
      const rs = await window.voteContract.methods.claimDepositReward(account).send({ from: store.state.accounts[0] });
      Toast.clear();
      resolve(rs);
    } catch (e) {
      Toast.clear();
      reject(e);
    }
  });
};

//领取投票收益
const getReceive = (account) => {
  return new Promise(async (resolve, reject) => {
    try {
      Toast.loading({
        duration: 0,
      });
      const rs = await window.voteContract.methods.claimVoteReward(account).send({ from: store.state.accounts[0] });
      Toast.clear();
      resolve(rs);
    } catch (e) {
      Toast.clear();
      reject(e);
    }
  });
};

//正式节点列表
const getFormalNodeList = (account,flag=true) => {
  return new Promise(async (resolve, reject) => {
    try {
     if(flag){
      Toast.loading({
        duration: 0,
      });
     }
      let res = await window.voteContract.methods.getOfficialNodeList().call({from: account});
      Toast.clear();
      resolve(res);
    } catch (e) {
      Toast.clear();
      reject(e);
    }
  });
};
//我的正式节点信息
const getNodeInfoV2 = (account) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await window.voteContract.methods.getNodeGeneralInfoV2().call({from: account});
      resolve(res);
    } catch (e) {
      reject(e);
    }
  });
};

//修改节点信息
const getModifyInfo = (account,node,sl,all) => {
  return new Promise(async (resolve, reject) => {
    try {
      Toast.loading({
        duration: 0,
      });
      const rs = await window.voteContract.methods.updateNode(node,sl,all).send({ from: account });
      Toast.clear();
      resolve(rs);
    } catch (e) {
      Toast.clear();
      reject(e);
    }
  });
};


//锁仓
const getClaim= (account) => {
  return new Promise(async (resolve, reject) => {
    try {
      Toast.loading({
        duration: 0,
      });
      let res = await window.lockUpContract.methods.claim().send({from: account});
      Toast.clear();
      resolve(res);
    } catch (e) {
      Toast.clear();
      reject(e);
    }
  });
};

//待领取INFTI
const getpendingTotal = (account) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await window.lockUpContract.methods.getUser(account).call();
      resolve(res);
    } catch (e) {
      reject(e);
    }
  });
};

//待领取INFTI
const getpendingInft = (account) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await window.lockUpContract.methods.pendingToken(account).call();
      resolve(res);
    } catch (e) {
      reject(e);
    }
  });
};

//nft列表
const getnftList = (account, page = 1, size = 10000) => {
  return new Promise(async (resolve, reject) => {
    try {
      Toast.loading({
        duration: 0,
      });
      const rs = await window.nftInfoContract.methods.getNFTList(account,page,size).call({ from: account });
      Toast.clear();
      resolve(rs);
    } catch (e) {
      Toast.clear();
      reject(e);
    }
  });
};
//持有nft总数
const getNFTNum = (account) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await window.nftInfoContract.methods.getNumOfLevel().call({from: account});
      resolve(res);
    } catch (e) {
      reject(e);
    }
  });
};

//兑换
const getExchange = (account,type,amount) => {
  return new Promise(async (resolve, reject) => {
    try {
      Toast.loading({
        duration: 0,
      });
      let res = await window.miningContract.methods.getReward(type,amount).send({from: account});
      Toast.clear();
      resolve(res);
    } catch (e) {
      Toast.clear();
      reject(e);
    }
  });
};

//待兑换积分
const getExchangeXnFT = (account) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await window.miningContract.methods.getEnableAmount(account).call();
      resolve(res);
    } catch (e) {
      reject(e);
    }
  });
};

//质押SNFT赎回
const getPleSNFTRede = (account,amount) => {
  return new Promise(async (resolve, reject) => {
    try {
      Toast.loading({
        duration: 0,
      });
      amount = BigNumberMul(amount, Math.pow(10, 18));
      let res = await window.miningContract.methods.withdraw(amount).send({from: account});
      Toast.clear();
      resolve(res);
    } catch (e) {
      Toast.clear();
      reject(e);
    }
  });
};

//质押SNFT
const getSNFT = (account,amount) => {
  return new Promise(async (resolve, reject) => {
    try {
      Toast.loading({
        duration: 0,
      });
      amount = BigNumberMul(amount, Math.pow(10, 18));
      const rs = await window.miningContract.methods.stake(amount).send({  from: account  });
      Toast.clear();
      resolve(rs);
    } catch (e) {
      Toast.clear();
      reject(e);
    }
  });
};

// 质押SNFT授权
const getSNFTApprove = () => {
  return new Promise(async (resolve, reject) => {
    try {
      Toast.loading({
        duration: 0,
      });
      const rs = await window.rTokenContract.methods.approve(miningAddress, "21000000000000000000000000").send({ from: store.state.accounts[0] });
      Toast.clear();
      resolve(rs);
    } catch (e) {
      Toast.clear();
      reject(e);
    }
  });
};
// 质押SNFT授权额度
const getSNFTAllowance = (account) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await window.rTokenContract.methods.allowance(account, miningAddress).call();
      resolve(res);
    } catch (e) {
      reject(e);
    }
  });
};

//挖矿信息
const getMiningInfo = (account) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res1 = await window.miningContract.methods.totalSupply().call({from: account});
      let res2 = await window.miningContract.methods.getNftSwapNum().call({from: account});
      let res3 = await window.miningContract.methods.balanceOf(account).call();
      let res4= await window.miningContract.methods.getScore().call();
      let obj = {};
      obj.snfttotal=res1
      obj.nftNum=res2
      obj.pleYourself=res3
      obj.outputPoints=res4
      resolve(obj);
    } catch (e) {
      reject(e);
    }
  });
};

//是否可以赎回
const getOrRede = (account) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await window.voteContract.methods.checkClaimDeposit(account).call({from: account});
      resolve(res);
    } catch (e) {
      reject(e);
    }
  });
};
//倒计时
const getCountdown = (account) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await window.voteContract.methods.nodeEndTime().call({from: account});
      resolve(res);
    } catch (e) {
      reject(e);
    }
  });
};
//质押赎回
const getPleRede = (account) => {
  return new Promise(async (resolve, reject) => {
    try {
      Toast.loading({
        duration: 0,
      });
      let res = await window.voteContract.methods.claimDeposit().send({from: account});
      Toast.clear();
      resolve(res);
    } catch (e) {
      Toast.clear();
      reject(e);
    }
  });
};

//投票赎回
const getVoteRede = (nodeaccount,amount) => {
  return new Promise(async (resolve, reject) => {
    try {
      Toast.loading({
        duration: 0,
      });
      amount = BigNumberMul(amount, Math.pow(10, 18));
      let res = await window.voteContract.methods.claimVote(nodeaccount,amount).send({from: store.state.accounts[0]});
      Toast.clear();
      resolve(res);
    } catch (e) {
      Toast.clear();
      reject(e);
    }
  });
};

//我的节点列表
const getVoteList = (account) => {
  return new Promise(async (resolve, reject) => {
    try {
      Toast.loading({
        duration: 0,
      });
      console.log(account);
      let res = await window.voteContract.methods.getPersonalVoteNodeInfoV2(account).call({from: account});
      Toast.clear();
      resolve(res);
    } catch (e) {
      Toast.clear();
      reject(e);
    }
  });
};
//我的节点信息
const getMyVoteInfo = (account) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await window.voteContract.methods.getPersonalVoteInfoV2(account).call();
      resolve(res);
    } catch (e) {
      reject(e);
    }
  });
};
//我的投票
const getNodeVote = (nodeaccount,amount) => {
  return new Promise(async (resolve, reject) => {
    try {
      Toast.loading({
        duration: 0,
      });
      amount = BigNumberMul(amount, Math.pow(10, 18));
      let res = await window.voteContract.methods.vote(nodeaccount,amount).send({from:store.state.accounts[0]});
      Toast.clear();
      resolve(res);
    } catch (e) {
      Toast.clear();
      reject(e);
    }
  });
};

//我的节点列表
const getMyNodeList = (account,flag=true) => {
  return new Promise(async (resolve, reject) => {
    try {
      // if(flag) {
      //   Toast.loading({
      //     duration: 0,
      //   });
      // }
      let res = await window.voteContract.methods.getPersonalNodeList(account).call({from: account});
      resolve(res);
      // Toast.clear();
    } catch (e) {
      reject(e);
      // Toast.clear();
    }
  });
};

//我的节点信息
const getNodeMyInfo = (account) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await window.voteContract.methods.getPersonalNodeInfoV2(account).call({from: account});
      resolve(res);
    } catch (e) {
      reject(e);
    }
  });
};

//节点报名
const getNodePartIn = (account,node,sl,all,amount) => {
  return new Promise(async (resolve, reject) => {
    try {
      Toast.loading({
        duration: 0,
      });
      amount = BigNumberMul(amount, Math.pow(10, 18));
      let res = await window.voteContract.methods.deposit(node,sl,all,amount).send({from: account});
      Toast.clear();
      resolve(res);
    } catch (e) {
      Toast.clear();
      reject(e);
    }
  });
};
//赎回授权
const getRedeApprove = () => {
  return new Promise(async (resolve, reject) => {
    try {
      Toast.loading({
        duration: 0,
      });
      const rs = await window.rTokenContract.methods.approve(voteAddress, "21000000000000000000000000").send({ from: store.state.accounts[0] });
      Toast.clear();
      resolve(rs);
    } catch (e) {
      Toast.clear();
      reject(e);
    }
  });
};
//赎回授权额度
const getRedeAllowance = (account) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await window.rTokenContract.methods.allowance(account, voteAddress).call();
      resolve(res);
    } catch (e) {
      reject(e);
    }
  });
};

//报名授权
const getNodeApprove = () => {
  return new Promise(async (resolve, reject) => {
    try {
      Toast.loading({
        duration: 0,
      });
      const rs = await window.voteNumContract.methods.approve(voteAddress, "21000000000000000000000000").send({ from: store.state.accounts[0] });
      Toast.clear();
      resolve(rs);
    } catch (e) {
      Toast.clear();
      reject(e);
    }
  });
};

//报名授权额度
const getNodeAllowance = (account) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await window.voteNumContract.methods.allowance(account, voteAddress).call();
      resolve(res);
    } catch (e) {
      reject(e);
    }
  });
};

//投票节点信息
const getNodeInfo = (account) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await window.voteContract.methods.getNodeGeneralInfo().call({from: account});
      resolve(res);
    } catch (e) {
      reject(e);
    }
  });
};
//节点列表
const getNodeList = (account,flag=true) => {
  return new Promise(async (resolve, reject) => {
    try {
    //  if(flag){
    //   Toast.loading({
    //     duration: 0,
    //   });
    //  }
      let res = await window.voteContract.methods.getNodeList().call({from: account});
      // Toast.clear();
      resolve(res);
    } catch (e) {
      // Toast.clear();
      reject(e);
    }
  });
};

//NFT余额
const getbalanceOfNFT = (account) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await window.voteNumContract.methods.balanceOf(account).call({from: account});
      resolve(res);
    } catch (e) {
      reject(e);
    }
  });
};
//NFT余额
const getbalanceOfSNFT = (account) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await window.rTokenContract.methods.balanceOf(account).call({from: account});
      resolve(res);
    } catch (e) {
      reject(e);
    }
  });
};
const popUpPrompts = (type, hashCode) => {
  store.commit('setState', {
    toastShow: true,
    toastShowType: type,
    transactionHash: hashCode,
  })
};

const hideAll = () => {
  store.commit('setState', {
    debtExtractShow: false,
    debtAddShow: false,
    debtRepayShow: false,
    debtCoinShow: false,
  })
};
const showLoading = () => {
  Toast.loading({
    duration: 9000,
    forbidClick: false,
    closeOnClick: true,
    closeOnClickOverlay: true,
  });
}

const hideLoading = () => {
  Toast.clear();
}


export {
  hideLoading,
  showLoading,
  initParams,
  initWeb3hooks,
  initWeb3,
  BigNumber,
  BigNumberStr,
  BigNumberMul,
  BigNumberDiv,
  BigNumberAdd,
  BigNumberSub,
  popUpPrompts,
  hideAll,
  getNodeInfo,
  getNodeList,
  getNodePartIn,
  getbalanceOfNFT,
  getNodeAllowance,
  getNodeApprove,
  getNodeMyInfo,
  getMyNodeList,
  getNodeVote,
  getMyVoteInfo,
  getVoteList,
  getVoteRede,
  getRedeApprove,
  getRedeAllowance,
  getPleRede,
  getCountdown,
  getbalanceOfSNFT,
  getOrRede,
  getMiningInfo,
  getSNFTAllowance,
  getSNFTApprove,
  getSNFT,
  getPleSNFTRede,
  getExchangeXnFT,
  getExchange,
  getNFTNum,
  getnftList,
  getpendingInft,
  getpendingTotal,
  getClaim,
  getModifyInfo,
  getNodeInfoV2,
  getFormalNodeList,
  getReceive,
  getPleReceive
}
