const lang_en = {
    "node":'Campaign',
    "nftMining":'NFT Mining',
    "mine":'About',
    "mynode":'My Node',
    "myvote":'My Vote',
    "mynft":'My NFT',
    "nodeelection":'Node Campaign',
    "wantrun":'CAMPAIGN',
    "pledgeUserList":'Stake user list',
    "fuhecanxuanzigejiedianshu":'Authorities',
    "bufuhecanxuanzigejiedianshu":'Non-Authorities',
    "jiedianzongdepiaoshu":'Total Votes',
    "canxuanjeidianliebiao":'List of Candidates',
    "fenpeibili":'Sharing Ratio',
    "toupiaorenshu":'Number of Voters',
    "dangqiandepiao":'Current Votes',
    "canxuanzige":'Qualifications',
    "wotoupiaode":"I'm Voting",  
    "fuhe":'Qualified',
    "bufuhe":'Not Qualified',
    "toupiao":'Vote',
    "fanhui":'Back',
    "canxuanjiedian":'Candidate',
    "julicanxuanjeishuhaiyou":'Tik Tok',
    "jiedianmingcheng":'Node Name',
    "jiediankouhao":'Node Slogan',
    // "fenpeibili":'Sharing Ratio',
    "diyadaibishu":'Staked',
    "shurujeidianmc":'Input Node Name within 30 letters',
    "shurujiediankuhao":'Input Node Slogan within 50 letters',
    "shurubili":'Input Sharing Ratio between 50 to 100',
    "shurudaibi":'Input the Number of $TFI Staked for Campaign. Minimum 2,100',
    "keyongyue":'Available Balance',
    "querencanxuan":'Confirm Candidacy',
    "canxuanjiedianguize":'Candidate Rules',
    "tishixinxi":'Input Details under Tips',
    "canxuanchengong":'Succeed',
    "querenzhong":'Confirming',
    "guangbojiaoyizhong":'Pending',
    "toupiaojiedianshu":'Nodes You Vote for',
    "toupiaozongshu":"Total Votes",
    "toupiaoliebiao":'Voting Node List',
    "toupiaoshu":'Votes',
    "zhuijiatoupiao":'Extra Votes',
    "shouyifenpeibili":'Sharing Ratio',
    "jiediandepiaozpongshu":'Total Votes of Node',
    "yonghutoupiaoshu":'Supporters Votes',
    "jiediantoupiaoshu":'Candidates votes',  //kong
    "jiediancanxuanzhiyashu":'Staked TFI of Candidate',
    "jiedianzhuanshulianjie":'Staking Invitation',
    "toupiaoyonghuliebiao":'Voting Users List',
    "piao":'Vote',
    "fuzhishibai":'Copy Failed',
    "toupiaogei":'Voting for',
    "toupiaoshuliang":'Votes',
    "queding":'Confirm',
    "toupiaochenggong":'Vote Succeed',
    "shuhuishuliang":'withdrawal Amount',
    "shuhuichenggong":'withdrawal Succeed',
    "zhanghuyuebuzu":'Balance Low',
    "tian":'Day',
    "shi":'Hour',
    "fen":'Minute',
    "miao":'Second',
    "shuhuishulaingbuke":'Withdrawal Cannot Exceed STFI amount',
    "shuhuishulaingbuzu":'Available Withdrawal Balance Low',
    "chuangjianjiedian":'Created Node',
    "meiyouchuangjianjiedian":'No Node Created',
    'qingshuruzhengqueshuzhi':'Input Correct Number',
    "bukechongfu":'Candidate Exists，',
    "yijieshu":'End',
    "jingxuanjieshu":'End',
    "NFTwakaung":'NFT Mining',
    "wakuangshuoming":'Stake sTFI into NFT Pool，and Use xTFI you earned to Swap Honor and Benefit NFTs',
    "wakuangshuomingqian":'Stake sTFI into NFT Pool，and Use xTFI you earned to Swap Honor and Benefit NFTs',
    "wakuangshuominghou":'Benefit Two Types of NFT',
    "STFIdiyazonglaing":"Total Staked sTFI",
    "yichanchujifen":'Farming xTFI Daily',
    "nftduihuanshu":"NFTs Balance",
    "jifen":'xTFI',
    "daiduihuajifen":'Available xTFI',
    "duihuanNFT":"Swap NFT",
    "yizhiyaSTFI":"Staked sTFI",
    "zhiya":"Staking",
    "NFTwakaungguize":'NFT Mining Rules',
    "a_shouquan": 'Authorize', 
    "chiyouNFTzongshu":"Total Hodl",
    "a_shouquanchenggong": 'Successful Authorization', //
    "a_shuhui": 'Withdraw',  //    
    "a_lianjieqianbao":'Connect Wallet',   //
    "honorNFTshu":"Honor",
    "BenefitNFTshu":'Benefit',
    "wodenftliebiao":"My NFT List",
    "quanbu":'All',
    'haimeiyoutupian':"No Available NFT",
    "a_zuida":'Maximum',
    "a_keyong":'Available',
    "a_haode": 'Okay',  //
    "a_chakanjiaoyi": 'Transactions', //
    "a_fuzhichneggon": 'Copy Succeed', //
    "a_querenzhong": 'Confirming',
    "bukeshuhui":'Irrevocability',
    "jiaoyichenggong":'Succeed',
    "zanwuoupiao":'No Vote',
    "zanwujiedian":'No Node',
    // "zhiya":'Staking',
    "a_queren":'Confirm',
    'xnftduihuannft':"xTFI Swap NFT",
    'nftleixing':"NFT type",
    'duihuannftshulaing':'Swap NFT Amount',
    'duihuanbili':'Swap Ratio:',
    'dangqiankelignqu':"Available",
    'yilingqu':"Received",
    "NFTwakaungguizeone":'Voting to get sTFI, mortgage sTFI for Liquidity Mining',
    "NFTwakaungguizetwo":'xTFI withdrawal is not allowed, but swapped to NFT',
    "NFTwakaungguizethree":'Hold NFT to enjoy various platform rights',
    "shuruzishuchaochuxianzhi":'The number of letters exceeds the limit',
    "xinxitianxiebueanzheng":'Incomplete information',
    "bukeshuruxiaoshu":"Do not enter decimals",
    "qingshuruguidingfanweineibili":'Please enter the ratio within the specified range',
    "shuridaibixiaoyuzuixiao":'The number of tokens is less than the minimum',
    'zhongzilun':'Locking Seed',
    "simulun":"Locking Private",
    'lingquchenggong':'Claim Succeed',
    'jiediancanxuanguizeone':'Fill in the details and submit it to become a candidate',
    'jiediancanxuanguizetwo':'The staked TFI needs to be locked when registering. but the successful Candidate could withdraw after staking period.After the end of the campaign the failed candidate could withdraw directly. ',
    'jiediancanxuanguizethree':'An official Node needs  the number of voters to reach 5 and the number of votes to reach 5000',
    'jiediancanxuanguizefour':'After the end of the campaign, the first two thirds of all qualified nodes had official nodes',
    "popo":'Conditions for Candidate: The number of voters reaches 5 and the number of votes reaches 5000. After the campaign period, the first 2/3 of all candidates will become nodes.',
    "zuiduokeduihuan":'A maximum of 10 NFTs can be redeemed each time',
    "duihuanchenggong":"Successful redemption",
    "xiugaixinxi":'Modify information',
    "quxiao":'cancel',
    "baocunxinxi":'Save information',
    "xiugaichenggong":"Successful",
    //二期
    "wodezhiya":'My pledge',
    "zhengshijiedian":'Official node',
    "zhengxiang":'Official nodes enjoy daily staking income',
    "officialNum":'Number of official nodes',
    "nodetotalpl":'Total Node Staking (TFI)',
    "noderevenue":'Total node revenue (TFI)',
    "pledgepeople":'Number of pledges',
    "pledgetotal":"Total pledge(TFI)",
    "totalrevenue":"Cumulative income(TFI)",
    "pledged":'I pledged',
    "pledgeNums":'Total pledge (TFI)' ,
    "onedayrevenue":'Daily Profit/Node (TFI)',
    "RedeemedNFT":'Number of redeemed NFTs',
    "leijifafangshouyi":'Cumulative Distributed Income (TFI)',
    "estimated":"Today's estimated release income (TFI)",
    "pendingrevenue":'Unclaimed node income',
    "pendingrevenue1":'Pledge income pending',
    "receive":'receive',
    "percentage":'Percentage',
    "pledgenodenum":'Number of staked nodes',
    "leijizhiyahsouyi":'Cumulative Received Income (TFI)',
    "dailingquzhiyashouyi":'Pledge income pending (TFI)',
    "clickreceive":'One-click collection',
    "totalple":"Total pledge number (TFI) : ",
    "myple":"My pledge (TFI):",
    "estimatedday":"Estimated Today's Earnings (TFI):",
    "jixuzhiya":"Continue to pledge",
    "pleFor":'Pledge to',
    "pleSucc":'Pledge success',
    "unPle":'No pledge',
    "nono":'The current available balance is zero',
    "pr":'The node election failed, please retrieve the pledged tokens as soon as possible',

































    



















  //AccountInfo
  "a_liulanqichakan": ' browser',
    "a_genghuanqianbao": 'Change your wallet',
    "a_duankailianjie": 'Disconnect',
  
  
  
 

}
export default lang_en
